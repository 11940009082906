<template>
  <div class="template-upload">
    <el-dialog
      :title="title ? title : 文件上传"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <div class="upload-container">
        <el-upload
          ref="upload"
          class="upload-area"
          :action="uploadUrl"
          :before-upload="beforeUpload"
          :http-request="uploadRequest"
          :on-success="handleSuccess"
          :on-error="handleError"
          :on-progress="handleProgress"
          :data="uploadData"
          :show-file-list="true"
          accept=".xls,.xlsx"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            <p>
              支持格式：xls, xlsx
              <span>仅允许导入xls、xlsx格式文件。</span>
              <a
                style="color: #409eff; border-bottom: 1px solid #409eff"
                :href="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/更新人工成本模版.xlsx?t=${Date.now()}`"
                >下载模板</a
              >
            </p>
          </div>
        </el-upload>
        <div v-if="uploadProgress > 0" class="upload-progress">
          <el-progress
            :percentage="uploadProgress"
            :format="progressFormat"
          ></el-progress>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadFile">上传</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
        <!-- <el-button type="primary" @click="downloadTemplate">下载模板</el-button> -->
      </span>
    </el-dialog>
    <!-- <el-button type="primary" size="small" @click="showDialog"
      >上传文件</el-button
    > -->
  </div>
</template>

<script>
export default {
  name: "TemplateUpload",
  data() {
    return {
      dialogVisible: false,
      uploadProgress: 0,
    };
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    // 模板文件名
    templateName: {
      type: String,
      required: true,
    },
    // 上传接口地址
    uploadUrl: {
      type: String,
      default: '',
      required: false,
    },
    // 上传时附加的数据
    uploadData: {
      type: Object,
      default: () => ({}),
    },
    uploadHandler: {
      type: Function,
      default: null, 
    },
    uploadApi: {
      type: Function,
      required: false,
    },
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.uploadProgress = 0;
    },
    // 下载模板
    downloadTemplate() {
      window.open(
        `${process.env.VUE_APP_FILEPATH}/template/${this.templateName}`
      );
      this.dialogVisible = false;
    },
    // 处理上传进度
    handleProgress(event) {
      this.uploadProgress = Math.round(event.percent);
    },
    // 格式化进度条文字
    progressFormat(percentage) {
      return percentage === 100 ? "上传完成" : `${percentage}%`;
    },
    uploadFile() {
      this.$refs?.upload.submit();
      this.$refs?.upload.clearFiles();
    },
    uploadRequest(options) {
      let fileObj = options.file;
      let fd = new FormData();
      fd.append("multipartFile", fileObj);
      this.uploadApi(fd)
        .then((res) => {
          if (res.code === 200) {
            this.uploadHandler()
            this.$message.success("上传成功");
            this.$emit("upload-success", res.data);
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传前校验
    beforeUpload(file) {
      const isExcel =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".xlsx");
      //   const isLtSize = file.size / 1024 / 1024 < this.fileSize

      if (!isExcel) {
        console.log("文件格式错误");
        this.$message.warning("只能上传 Excel 文件!");
        return false;
      }
      return true;
    },
    // 上传成功
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success("上传成功");
        this.$emit("upload-success", response.data);
        this.dialogVisible = false;
      } else {
        this.$message.error(response.message || "上传失败");
      }
    },
    // 上传失败
    handleError(err) {
      this.$message.error("上传失败，请重试");
    },
  },
};
</script>

<style lang="scss" scoped>
.template-upload {
  .upload-container {
    padding: 20px;
  }
  .upload-area {
    width: 100%;
    .el-upload {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
          border-color: #409eff;
        }
        .el-icon-upload {
          font-size: 48px;
          color: #909399;
          margin-bottom: 10px;
        }
        .el-upload__text {
          color: #606266;
          em {
            color: #409eff;
            font-style: normal;
          }
        }
      }
    }
    // 添加这些样式
    :deep(.el-upload.el-upload--text) {
      width: 100%;
      display: block;
    }
    :deep(.el-upload-dragger) {
      width: 100%;
    }
  }
  .el-upload__tip {
    text-align: center;
    margin-top: 10px;
    p {
      margin: 5px 0;
      font-size: 12px;
      color: #909399;
    }
  }
  .upload-progress {
    margin-top: 20px;
  }
}
</style>
